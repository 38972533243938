import React from "react"
//import ScheduleConsultForm from "../components/scheduleconsultform"
import Layout from "../components/layout"
import loadable from '@loadable/component'

const ScheduleConsultForm = loadable(() => import('../components/scheduleconsultform'))

const ScheduleConsult = () => {
    return (
        <Layout>
            <ScheduleConsultForm />                        
        </Layout>
    )
}

export default ScheduleConsult